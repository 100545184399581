import { BoundedContent, GridContainer, Row, Column, H1, H2 } from '@vp/swan'

export const PageHeading = ({ title, subtitle }: PageHeadingProps) => {
  return (
    <BoundedContent paddingX={0}>
      <GridContainer>
        <Row
          marginLeft={{ xs: '5', md: '8' }}
          marginRight={{ xs: '5', md: '8' }}
          marginTop={{ xs: '5', md: '7' }}
          marginBottom={{ xs: '3', md: '7' }}
        >
          <Column span={12} padding={0}>
            <H1 fontSize='x3large' fontWeight='bold' mb='4' fontSkin='title-headline'>
              {title}
            </H1>
            {subtitle && (
              <H2 fontSize='large' fontWeight='normal' mb='0' fontSkin='body-standard'>
                {subtitle}
              </H2>
            )}
          </Column>
        </Row>
      </GridContainer>
    </BoundedContent>
  )
}

export type PageHeadingProps = {
  title: string
  subtitle: string
  locale?: string
}
