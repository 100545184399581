import { Card, FlexBox, Typography, SWAN_STYLE_KEY_MAP } from '@vp/swan'
import { useLogger, useSwanStyleKeys } from '@vp/ubik-context'
import { PageHeading, PageHeadingProps } from './components/PageHeading'
import { ContentfulContextProvider, ContentfulLink, DebugSection } from '@vp/mcs-react-tools'

export const Fragment = ({ title, subtitle, locale, contentfulMetadata, debugData }: PageHeadingProps & { contentfulMetadata: any, debugData: any }) => {
  const logger = useLogger()
  logger.log('Rendering fragment for a locale', locale)

  // TODO: Remove toggleSwitch once Bookends has updated their keys
  useSwanStyleKeys([SWAN_STYLE_KEY_MAP.core, SWAN_STYLE_KEY_MAP.toggleSwitch])

  const search = typeof window !== 'undefined' ? window.location.search : ''

  return (
    <ContentfulContextProvider space={contentfulMetadata?.spaceId || ''}>
      <DebugSection
        contentfulMetadata={contentfulMetadata}
        availability={debugData}
        search={search}
      />
      <ContentfulLink id={contentfulMetadata?.contentful_id} />
      <FlexBox>
        <Card style={{ width: '100%' }} padding={0}>
          <Typography fontWeight='bold' mb={1} mr={1}>
            <PageHeading title={title} subtitle={subtitle} />
          </Typography>
        </Card>
      </FlexBox>
    </ContentfulContextProvider>
  )
}
